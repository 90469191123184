<template>
  <div>
    <div class="text-h3 text-md-h2 font-weight-bold mt-8 mb-2">
      Servicios del proveedor
    </div>
    <ListCards :cards="formatServices(services)" type="Service" class="mb-16" />
  </div>
</template>
<script>
import ListCards from "@/components/User/Shared/ListCards.vue";

export default {
  components: {
    ListCards,
  },
  props: {
    services: { type: Array, required: true },
  },
  methods: {
    formatServices(services) {
      const data = services.map((service) => {
        const info = {
          ...service,
          providerName: this.services.name,
          rating:
            service.serviceComment.reduce(
              (total, currentValue) => total + currentValue.score,
              0
            ) / service.serviceComment.length,
          reviews: service.serviceComment.length,
        };
        return info;
      });
      return data;
    },
  },
};
</script>
