<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '10px' }
          : { 'padding-bottom': '10px' },
      ]"
      class="body-container"
    >
      <v-container v-if="provider && !loading" class="px-10 px-md-4 pb-md-0">
        <div>
          <div class="d-flex">
            <div class="text-h2 text-md-h1 font-weight-bold mx-0">
              {{ provider.name }}
            </div>
            <v-spacer></v-spacer>
            <!-- <Button
              outlined
              rounded="md"
              :icon="favorite ? 'fa-heart' : 'far fa-heart'"
              :aria-label="
                favorite
                  ? 'Remover proveedor de favoritos'
                  : 'Añadir proveedor a favoritos'
              "
              @event="setFavorite()"
            /> -->
          </div>
          <div class="flex-row mb-4 ml-md-1">
            <span
              class="mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
              style="color: inherit"
            >
              <span>
                Proveedor Zaturna desde:
                <span class="text-capitalize">
                  {{
                    convertUnixToSemiCompleteDate(providerRegistrationUnixDate)
                  }}
                </span>
              </span>
            </span>
            <span
              class="mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
              style="color: inherit"
            >
              <v-icon class="mr-2 secondary--text" small> fa-map-pin </v-icon>
              <a
                class="text-decoration-underline mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
                style="color: inherit"
                @click="handleLocationModal()"
              >
                {{ provider.city }}, {{ provider.state }}
              </a>
            </span>
            <span
              v-if="provider.service.length > 0"
              class="mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
              style="color: inherit"
            >
              <v-icon
                v-if="provider.service[0].allCities"
                class="mr-2 secondary--text"
                small
              >
                far fa-check-circle
              </v-icon>
              <v-icon v-else class="mr-2 secondary--text" small>
                far fa-times-circle
              </v-icon>
              <span
                v-if="provider.service[0].allCities"
                class="mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
                style="color: inherit"
              >
                <span> Servicio en otras ciudades</span>
              </span>
              <span
                v-else
                class="mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
                style="color: inherit"
              >
                <span> No tiene servicio en otras ciudades</span>
              </span>
            </span>
            <!-- <v-icon class="mr-2 secondary--text" small> fa-star </v-icon>
            <span
              class="text-decoration-underline mr-sm-8 text-subtitle-1 text-sm-h6 font-weight-regular d-flex d-sm-inline-flex"
            >
              <span class="mr-sm-2"> {{ getReviewAverage() }} </span>
              <a style="color: inherit" @click="handleReviewsModal()">
                <span>
                  ({{
                    provider.providerComment[0].score == null
                      ? 0
                      : provider.providerComment.length
                  }}
                  reseñas)</span
                >
              </a>
            </span> -->
          </div>
          <Button
            :text="backRoute"
            pill
            outlined
            :height="44"
            prepend-icon="fa-caret-left"
            aria-label="Volver atrás"
            @event="$router.go(-1)"
          />
        </div>
        <div class="my-3">
          <v-tabs
            v-model="tabSelected"
            color="secondary"
            background-color="transparent"
            show-arrows
            slider-size="5"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              v-for="(tab, i) in tabs"
              :key="i"
              class="btn-background-transparent text-capitalize black--text text-h3 px-3 px-sm-6"
              :ripple="false"
              :active-class="'font-weight-bold'"
            >
              {{ tab }}
            </v-tab>
            <v-tabs-items v-model="tabSelected">
              <v-divider color="secondary"></v-divider>
              <v-tab-item>
                <!-- :provider="provider" -->
                <ProfileSection
                  :provider="provider"
                  :handle-modal="handleModalCall"
                />
              </v-tab-item>
              <v-tab-item>
                <ServicesSection :services="provider.service" />
              </v-tab-item>
              <v-tab-item>
                <PackagesSection :packages="provider.providerPackages" />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </div>
      </v-container>
      <v-container v-else>
        <InlineLoading />
      </v-container>
    </div>
    <Footer />
    <ModalLocation
      v-if="provider && !loading"
      :dialog="locationModal"
      :name="provider.name"
      :address="getFullAddress()"
      @closeModal="handleLocationModal"
    />
    <!-- <ModalOtherLocation
      :dialog="otherLocationsModal"
      :locations="provider.otherLocations"
      @closeModal="handleOtherLocationModal"
    /> -->
    <!-- <ModalReviews
      :dialog="reviewsModal"
      :name="provider.name"
      :reviews="provider.reviews"
      @closeModal="handleReviewsModal"
    /> -->
  </div>
</template>

<script>
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import Button from "@/components/Shared/Button.vue";
import ProfileSection from "@/components/User/Provider/ProfileSection.vue";
import ServicesSection from "@/components/User/Provider/ServicesSection.vue";
import PackagesSection from "@/components/User/Provider/PackagesSection.vue";
import ModalLocation from "@/components/Shared/Modal/ModalLocation.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
//import ModalOtherLocation from "@/components/Shared/Modal/ModalOtherLocation.vue";
//import ModalReviews from "@/components/Shared/Modal/ModalReviews.vue";
import Footer from "@/components/Shared/Footer.vue";
import { GET_PROVIDER_COMPLETE } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import {
  convertUnixToSemiCompleteDate,
  convertDateToCstTimeZoneUnix,
} from "@/Utils/dateConverter";
import { formatProviderAddress } from "../../Utils/Utils";

export default {
  name: "Provider",
  components: {
    Navbar,
    UserNavbar,
    SearchBar,
    Button,
    ProfileSection,
    ServicesSection,
    PackagesSection,
    ModalLocation,
    InlineLoading,
    //ModalOtherLocation,
    //ModalReviews,
    Footer,
  },
  data: () => ({
    provider: {},
    loading: false,
    backRoute: null,
    // ! Change way of getting card favorite state
    favorite: false,
    tabSelected: 0,
    locationModal: false,
    otherLocationsModal: false,
    reviewsModal: false,
    convertUnixToSemiCompleteDate: convertUnixToSemiCompleteDate,
  }),
  computed: {
    providerRegistrationUnixDate() {
      return convertDateToCstTimeZoneUnix(this.provider.insertedAt);
    },
    tabs() {
      let tabs = ["Perfil"];
      if (this.provider.service.length > 0) {
        tabs.push("Servicios");
      }
      if (this.provider.providerPackages.length > 0) {
        tabs.push("Paquetes");
      }
      return tabs;
    },
  },
  async created() {
    this.loading = true;
    await this.fetchSingleProvider();
    this.loading = false;
  },
  methods: {
    handleOtherLocationModal() {
      this.otherLocationsModal = !this.otherLocationsModal;
    },
    handleLocationModal() {
      this.locationModal = !this.locationModal;
    },
    handleReviewsModal() {
      this.reviewsModal = !this.reviewsModal;
    },
    handleModalCall(functionName, event) {
      this[functionName](event);
    },
    setFavorite() {
      this.favorite = !this.favorite;
    },
    async fetchSingleProvider() {
      const providerId = parseInt(this.$route.params.id);
      const { data, errors } = await useQuery(GET_PROVIDER_COMPLETE, {
        id: providerId,
      });
      if (data) {
        this.provider = {
          ...data.provider,
          providerPackages: data.providerPackages,
        };
      } else if (errors) {
        console.log(errors);
      }
    },
    getReviewAverage() {
      let score = 0;
      if (this.provider.providerComment[0].score !== null) {
        score = this.provider.providerComment
          .map((item) => item.score)
          .reduce((prev, curr) => prev + curr, 0);
        score = score / this.provider.providerComment.length;
      }
      return score;
    },
    getFullAddress() {
      return formatProviderAddress(this.provider);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path.includes("usuario/proveedores")) {
        vm.backRoute = "Volver a proveedores";
      } else if (from.path.includes("usuario/servicio/")) {
        vm.backRoute = "Volver a servicio";
      } else if (
        from.path.includes("proveedor/solicitudes/") ||
        from.path.includes("usuario/mis-solicitudes/")
      ) {
        vm.backRoute = "Volver a solicitud";
      } else {
        vm.backRoute = "Volver";
      }

      next();
    });
  },
};
</script>

<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}
</style>
